$.stellar({
    responsive: true
});

$('#hamburger').on('click', function () {
    $('body').toggleClass('menu-open');
});

$('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname
        ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function () {
                });
            }
        }
    });


$(document).ready(function () {
    $('.selectpicker').selectpicker();
    $(".bootstrap-select").click(function () {
        $(this).addClass("open");
    });

    $('#datepicker').datepicker({
        format: 'yyyy-MM-dd',
        language: $('html').attr('lang'),
        beforeShowDay: function (day) {
            return Calendar.markDay(day);
        }
    }).on('changeDate', function (event) {
        $("#SearchItem_date").val(event.date.toString('yyyy-MM-dd'));
        $("#SearchItem_date").closest('form').submit();
    });

    var PlacesBoxSlider = new Swiper('.places-box-slider .swiper-container', {
        nextButton: '.places-box-slider .next-button',
        prevButton: '.places-box-slider .prev-button',
    });

    var detailsViewSlider = new Swiper('.details-view-slider', {
        nextButton: '.details-view-slider .next-button',
        prevButton: '.details-view-slider .prev-button',
        pagination: '.details-view-slider .swiper-pagination',
        paginationClickable: true
    });

    var relatedSlider = new Swiper('.related-slider', {
        nextButton: '.related-slider .next-button',
        prevButton: '.related-slider .prev-button',
        slidesPerView: 3,
        spaceBetween: 15,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        breakpoints: {
            992: {
                slidesPerView: 2,
            },
            580: {
                slidesPerView: 1,
            }
        }
    });

    var imagesSlider = new Swiper('.images-slider', {
        nextButton: '.images-slider .next-button',
        prevButton: '.images-slider .prev-button',
        pagination: '.images-slider .swiper-pagination',
        paginationClickable: true,
        slidesPerView: 1
    });


    $('.clear-filters').click(function () {
        $('#item-form').find('input:text, input:password, select, textarea').val('');
        $('#item-form').submit();
    });

    $(".planner-button").on('click', function (event) {
        event.preventDefault();
        var $this = $(this);
        $.ajax({
            type: 'get',
            url: $(this).attr('href'),
            success: function () {
                var text = null;
                var textColor = 'white';
                var color = '#B51D21';
                if($this.hasClass('remove')) {
                    text = $this.data('text-removed');
                    if ($this.data('remove')) {
                        $this.parent().slideUp('slow');
                    } else {
                        $this.removeClass('remove');
                    }

                } else {
                    $this.addClass('remove');
                    text = $this.data('text-added');
                    color = '#7bc63e';
                }
                if (text != null) {
                    $.toast({
                        text: '<div class="toast-text">' + text + '</div>',
                        showHideTransition: 'fade',
                        bgColor: color,
                        textColor: textColor,
                        allowToastClose: false,
                        hideAfter: 1500,
                        stack: 0,
                        textAlign: 'center',
                        position: 'bottom-center'
                    });
                }
            }
        });
    });

    $('.wcag.font').click(function (event) {
        event.preventDefault();

        var fontSize = 22;
        var value = null;
        if (isNaN(parseInt(Cookies.get('font-size')))) {
            value = fontSize + 'px';
            $('html').css('font-size', value);
        } else {
            $('html').css('font-size', '');
        }
        $.cookie('font-size', value, {expires: 300});
    });

    $('.wcag.contrast').click(function (event) {
        event.preventDefault();

        var themeClass = 'contrasted';
        var current = $.cookie('body-theme');
        if (current === undefined || current != themeClass) {
            current = themeClass;
            $('body').addClass(themeClass);
        } else {
            current = '';
            $('body').removeClass(themeClass);
        }

        $.cookie('body-theme', current, {expires: 300});
    });

    setTimeout(function () {$('#planner-link').removeClass('collapsed');}, 500);


});

var eventsSlider = new Swiper('.swiper-container.events-slider', {
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 3,
    spaceBetween: 15,
    nextButton: '.events-slider .next-button',
    prevButton: '.events-slider .prev-button',
    breakpoints: {
        767: {
            slidesPerView: 2,
        },
        580: {
            slidesPerView: 1,
        }
    }
});



$('.view-change .list-view-trigger').on('click', function () {
    $('.view-change a').removeClass('active');
    $(this).addClass('active');
    $('body').removeClass('map-view-active');
});

$('.view-change .map-view-trigger').on('click', function () {
    $('.view-change a').removeClass('active');
    $(this).addClass('active');
    $('body').addClass('map-view-active');

    if (typeof window.map !== typeof undefined && typeof window.bounds !== typeof undefined) {
        setTimeout(function () {
            window.map.fitBounds(window.bounds);
        }, 100);
    }

});

$('#places-box-trigger').on('click', function () {
    var slider = $('.places-on-map');
    slider.toggleClass('open');
    if(slider.hasClass('open')) {
        TSGMap.fitObjects();
    }
});

var Calendar = {
    xhr: null,
    data: {},

    markDay: function (date) {
        var hasEvent = Calendar.getDayData(date), config = {};

        if (hasEvent)
            config.classes = 'has-day';

        return config;
    },

    getDayData: function (date) {
        var index = date.toString('yyyy-MM'),
            day = date.toString('yyyy-MM-dd'),
            year = date.toString('yyyy'),
            month = date.toString('MM');

        if (typeof Calendar.data[index] !== typeof undefined) {
            return Calendar.data[index].indexOf(day) !== -1;
        }

        if (Calendar.xhr && Calendar.xhr.readyState !== 4) {
            Calendar.xhr.abort();
        }

        Calendar.xhr = $.ajax('/item/calendar', {
            method: 'GET',
            async: false,
            data: {
                year: year,
                month: month
            },
            success: function (data) {
                Calendar.data[index] = data;
            }
        });

        return Calendar.data[index].indexOf(day) !== -1;
    }
};
